<template>
  <article class="pt-12 md:pt-0 md:max-w-1200 md:mx-auto">

      <!--宅配-->
      <article class="bg-white pt-1 mt-3">
        <section class="container-wrapper">
          <header class="text-2xl font-bold text-brown mb-4">
            寄送地址
          </header>
          <article class="flex flex-row justify-between items-center border-b mb-1">
            <div class="flex flex-row items-center">
              <img src="../../assets/img/logo/icon_house.svg" class="w-5 h-5 mr-2" />
              <h1 class="text-xl text-brown my-2">宅配</h1>
            </div>
            <div>
              <button class="btn-basic w-full m-0 py-0 px-3 block md:hidden" @click="toAddPage()">+ 新增地址</button>
              <button class="btn-basic w-full m-0 py-0 px-3 hidden md:block" @click="handleOpen()">+ 新增地址</button>
            </div>
          </article>

          <article >
            <div class="flex justify-between items-center py-4" v-for="item in addresses" :key="item.id">
              <div>
                <div class="flex flex-row items-center">
                  <button class="text-white bg-gray-200 h-6 w-6 flex items-center justify-center rounded-full mr-2" @click="deleteAddressData(item.id)"><img class="h-2 w-2" src="../../assets/img/icon_close.svg" /></button>
                  <div><span class="mr-4">{{ item.name }}</span><span class="mr-4">{{ item.mobile }}</span></div>
                </div>
                <div class="flex flex-row w-100">
                  <span class="h-6 w-6 mr-2"></span><span class="opacity-60">{{ item.email }}</span>
                </div>
                <div class="flex flex-row">
                  <span class="h-6 w-6 mr-2"></span><span class="opacity-60">{{ item.county }}{{item.district}}{{item.detail}}</span>
                </div>
              </div>
              <button class="bg-gray-200 h-8 w-8 items-center justify-center rounded-full flex md:hidden" @click="toEditPage(item.id)">
                <a-icon type="edit" />
              </button>
              <button class="bg-gray-200 h-8 w-8  items-center justify-center rounded-full hidden md:flex" @click="handleOpen(item)">
                <a-icon type="edit" />
              </button>
            </div>
          </article>
        </section>
      </article>

      <HomeAddressModal :visible="visible" @onClose="handleClose" :dataSource="dataSource"/>

      <!--7-11-->
      <!-- <section class="bg-white mt-3">
        <div class="container-wrapper">
          <header class="flex flex-row justify-between items-center border-b mb-1">
            <div class="flex flex-row items-center">
              <img src="../../assets/img/logo/icon_711.svg" class="w-5 h-5 mr-2" />
              <h1 class="text-xl text-brown my-2">7-11</h1>
            </div>
            <div>
              <button class="btn-basic btn-block py-0 px-3">+ 新增門市</button>
            </div>
          </header>
          <article>
            <div class="flex flex-col py-4" v-for="(item, idx) in mockDataAddress.shopFamily" :key="idx">
              <div class="flex flex-row items-center">
                <button class="text-white bg-gray-200 h-6 w-6 flex items-center justify-center rounded-full mr-2"><img class="h-2 w-2" src="../../assets/img/icon_close.svg" /></button>
                <div>{{ item.name }}</div>
              </div>
              <div class="flex flex-row">
                <span class="h-6 w-6 mr-2"></span><span class="opacity-60">{{ item.address }}</span>
              </div>
            </div>
          </article>
        </div>
      </section> -->

      <!--全家-->
      <!-- <section class="bg-white mt-3">
        <div class="container-wrapper">
          <header class="flex flex-row justify-between items-center border-b mb-1">
            <div class="flex flex-row items-center">
              <img src="../../assets/img/logo/icon_fami.svg" class="w-5 h-5 mr-2" />
              <h1 class="text-xl text-brown my-2">全家</h1>
            </div>
            <div>
              <button class="btn-basic btn-block py-0 px-3">+ 新增門市</button>
            </div>
          </header>
          <article>
            <div class="flex flex-col py-4" v-for="(item, idx) in mockDataAddress.shopFamily" :key="idx">
              <div class="flex flex-row items-center">
                <button class="text-white bg-gray-200 h-6 w-6 flex items-center justify-center rounded-full mr-2"><img class="h-2 w-2" src="../../assets/img/icon_close.svg" /></button>
                <div>{{ item.name }}</div>
              </div>
              <div class="flex flex-row">
                <span class="h-6 w-6 mr-2"></span><span class="opacity-60">{{ item.address }}</span>
              </div>
            </div>
          </article>
        </div>
      </section> -->

    <!-- </div> -->

  </article>
</template>
<script>
const mockDataAddress = {
  home: [
    { name: '住宅', address: '台北市松山區市民大道路四段105號' },
    { name: '住宅', address: '台北市松山區市民大道路四段105號' }
  ],
  shopSeven: [
    { name: '市大門市', address: '台北市松山區市民大道路四段105號' },
    { name: '市大門市', address: '台北市松山區市民大道路四段105號' }
  ],
  shopFamily: [
    { name: '市大門市', address: '台北市松山區市民大道路四段105號' },
    { name: '市大門市', address: '台北市松山區市民大道路四段105號' }
  ]
}

//假資料 7-11地址
const mockShipShop = {
  name: '市大門市',
  address: '台北市松山區市民大道路四段105號',
  isSelected: false //7-11有沒有選擇
}

import { mapActions, mapGetters } from 'vuex'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import HomeAddressModal from './HomeAddressModal.vue'

export default {
  name: 'addresses',
  components: {
    HomeAddressModal
  },
  data() {
    return {
      mockDataAddress,
      mockShipShop,
      visible: false,
      form:{
        type:0
      },
      isEdit: false,
      dataSource: {},
    }
  },
  methods: {
    ...mapActions(['getAddressesData','deleteAddressData']),
    toAddPage() {
      this.$router.push({ name: 'addressesAdd' })
    },
    toEditPage(id) {
      this.$router.push({ name: 'addressesEdit', params: {id} })
    },
    handleGetAddressData(){
      let params = omitBy(this.form, isEmpty)
      this.getAddressesData(params)
    },
    handleOpen(item) {
      this.isEdit = !!item
      this.dataSource = this.isEdit ? {...item} : {}
      this.visible = true
    },
    handleClose() {
      this.isEdit = false
      this.dataSource = {}
      this.visible = false
    }
  },
  computed: {
    ...mapGetters(['addresses']),
    routerName() {
      return this.$route.name
    }
  },
  mounted() {
    this.handleGetAddressData()
  }
}
</script>
<style lang="postcss">
.header-shadow-bottom{
  height: 8px;
  box-shadow: 0 8px 8px #000;
}
</style>